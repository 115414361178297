<!--
 * @Author: wxb
 * @Date: 2021-07-15 23:42:52
 * @LastEditTime: 2021-08-16 10:58:41
 * @LastEditors: wxb
 * @Description:
 * @FilePath: \ui-projectd:\projects\zhzj\ui-djz\src\views\Subject\buildExhiDetail.vue
-->
<template>
  <div class="containner">
    <headerNav :activeIdx='6'></headerNav>
    <div class="content">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{path:'/special'}">专题</el-breadcrumb-item>
        <el-breadcrumb-item :to="{path:'/buildExhi'}">已办展会</el-breadcrumb-item>
        <el-breadcrumb-item>已办展会详情</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="notice-panel"
           v-show="canSee">
        <div class="notice-title">{{exhibitionInfo.exhibitionname}} </div>

        <div class="notice-detail">
          <table class="table table-border table-bordered">
            <tr>
              <td class="text-r notice-content-title"
                  width="150">届期</td>
              <td class="notice-content-info">{{exhibitionInfo.thedeadline}} </td>
            </tr>
            <tr>
              <td class="text-r notice-content-title"
                  width="150">展馆名称</td>
              <td class="notice-content-info">{{exhibitionInfo.exhibitionhall}} </td>
            </tr>
            <tr>
              <td class="text-r notice-content-title">展馆地址</td>
              <td class="notice-content-info">{{exhibitionInfo.exhibitionaddress}} </td>
            </tr>
            <tr>
              <td class="text-r notice-content-title">展会网址</td>
              <td class="notice-content-info">
                <el-link :underline="false"
                         type="primary"
                         :href="exhibitionInfo.website"
                         target="_blank">{{exhibitionInfo.website}}</el-link>
              </td>
            </tr>
            <tr>
              <td class="text-r notice-content-title">开始时间</td>
              <td class="notice-content-info">{{exhibitionInfo.starttime}} </td>
            </tr>
            <tr>
              <td class="text-r notice-content-title">结束时间</td>
              <td class="notice-content-info">{{exhibitionInfo.endtime}} </td>
            </tr>
            <tr>
              <td class="text-r notice-content-title"
                  width="150">主办单位</td>
              <td class="notice-content-info">{{exhibitionInfo.organizer}} </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="file-panel"
           v-show="canSee">
        <div class="file-panel-title">参展企业</div>
        <div class="file-panel-content">
          <div class="project-search clearfix">
            <div class="project-search-input">
              <el-input class="project-search-field"
                        v-model="searchForm.keyword"
                        placeholder="输入关键词搜索..."
                        clearable
                        @keyup.enter.native="searchNotice"></el-input>
              <button type="button"
                      class="project-search-btn"
                      @click="searchNotice">搜索</button>
            </div>
          </div>
          <div class="exhibition-list">
            <el-table :data="resultLst"
                      stripe
                      border
                      style="width: 100%"
                      @sort-change="handleSortChanger">
              <template slot="empty">
                <noData tips="哎呀，未查询到相关参展企业信息"></noData>
              </template>
              <el-table-column prop="ENTERPRISE_NAME"
                               sortable='custom'
                               label="企业名称"
                               :show-overflow-tooltip="true">
              </el-table-column>
              <el-table-column prop="PAVILION_NO"
                               sortable='custom'
                               label="展馆号">
              </el-table-column>
              <el-table-column prop="BOOTH_NUMBER"
                               sortable='custom'
                               label="展位号">
              </el-table-column>
              <el-table-column prop="SCOPE_OF_EXHIBITION"
                               sortable='custom'
                               label="参展范围"
                               :show-overflow-tooltip="true">
              </el-table-column>
              <el-table-column prop="EXHIBITS_INFO"
                               sortable='custom'
                               label="展品信息"
                               :show-overflow-tooltip="true">
              </el-table-column>
            </el-table>
            <div class="page-panel">
              <el-pagination background
                             layout="prev, slot, next"
                             :total="total"
                             :current-page.sync="currentPage"
                             :hide-on-single-page="true"
                             @current-change="handleCurrentChange">
                <span class="current-page">{{currentPage}}</span>
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
      <viporlogin bgStr="5"
                  :type="viporlogin"
                  v-show="!canSee"></viporlogin>
      <dialogviporlogin ref="dialogviporlogin"></dialogviporlogin>
    </div>
    <footerNav></footerNav>
  </div>
</template>

<script>
import headerNav from '@/components/headerNavBlue'
import footerNav from '@/components/footerNav'
import { authenticate } from '@/utils/authenticate'
import { getToken } from '@/utils/auth'
import viporlogin from '@/components/viporlogin'
import noData from '@/components/noData'
import { mapGetters } from 'vuex'
import { doneExhibitionDetails, exhibitionEnterpriseList } from '@/api/subject'
import dialogviporlogin from '@/components/dialogviporlogin'
export default {
  name: 'buildExhiDetail',
  components: {
    headerNav,
    footerNav,
    viporlogin,
    dialogviporlogin,
    noData
  },
  data() {
    return {
      viporlogin: 'login',
      canSee: true,
      id: '',
      exhibitionInfo: {},
      searchForm: {
        keyword: '',
        exhibitionName: '',
        thedeadline: '',
        columnName: '',
        order: '',
        start: 0,
        limit: 10
      },
      resultLst: [{}],
      currentPage: 1,
      total: 0
    }
  },
  computed: {
    ...mapGetters({
      memberType: 'memberType'
    })
  },
  methods: {
    authCheck() {
      const authObj = authenticate('buildExhiDetail', this.memberType)
      if (authObj.flag) {
        // 调用查询列表接口
        this.initPage()
        this.canSee = true
      } else {
        // 根据msg值不同展示不同的提示信息
        this.canSee = false
        this.viporlogin = authObj.msg
      }
    },
    initPage() {
      const params = {
        id: this.id
      }
      doneExhibitionDetails(params).then(res => {
        if (res.ok) {
          this.exhibitionInfo = res.data
          this.searchForm.exhibitionName = res.data.exhibitionname
          this.searchForm.thedeadline = res.data.thedeadline
          this.getList()
        }
      }).catch(err => {
        console.log(err)
      })
    },
    getList() {
      exhibitionEnterpriseList(this.searchForm).then(res => {
        if (res.ok) {
          this.resultLst = res.lst
          // 条数限制
          if (res.cnt > 100) {
            this.total = 100
          } else {
            this.total = res.cnt
          }
        } else {
          this.$message.error('获取信息异常，请稍后再试')
        }
      }).catch(err => {
        console.log(err)
      })
    },
    searchNotice() {
      this.searchForm.start = 0
      this.currentPage = 1
      this.getList()
    },
    // 处理分页
    handleCurrentChange(val) {
      this.searchForm.start = (val - 1) * this.searchForm.limit
      this.getList()
    },
    // 排序
    handleSortChanger(prop) {
      this.searchForm.start = 0
      this.currentPage = 1
      if (prop.order) {
        this.searchForm.columnName = prop.prop
        this.searchForm.order = prop.order === 'ascending' ? 'asc' : 'desc'
      } else {
        this.searchForm.columnName = ''
        this.searchForm.order = ''
      }
      this.getList()
    }
  },
  mounted() {
    const id = this.$route.params.id
    this.id = id
    if (getToken()) {
      if (this.memberType === 'tourist') {
        this.$store.dispatch('getMemberInfo').then(res => {
          this.authCheck()
        })
      } else {
        this.authCheck()
      }
    } else {
      this.authCheck()
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  width: 144rem;
  margin: 0 auto;
}

.el-breadcrumb {
  height: 5.4rem;
  line-height: 5.4rem;
}

.notice {
  &-panel {
    width: 144rem;
    margin: 0 auto;
    border: 1px solid #eee;
    padding: 15px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
  }

  &-title {
    font-size: 16px;
    text-align: center;
    margin-bottom: 10px;
  }

  &-content-title {
    background: #f4f4ff;
  }
}

.file-panel {
  margin-top: 2rem;
  margin-bottom: 2rem;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  min-height: 50rem;
  &-title {
    font-size: 18px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 16px;
    font-weight: 500;
    border-bottom: 1px solid #ebeef5;
    padding: 1.5rem;
  }

  &-content {
    padding: 1.5rem;
  }
}

// 搜索框
.project {
  &-search {
    background: #ffffff;
    margin-bottom: 15px;
    &-input {
      width: 660px;
      height: 40px;
      display: flex;
      float: right;
    }

    &-field {
      flex: 1;
      border: solid 2px #398ce2;
      background-color: #ffffff;
      font-size: 14px;
      font-weight: normal;
      letter-spacing: 0px;
      color: #898a8c;
      /deep/ .el-input__inner {
        border-radius: 0;
        height: 36px;
        line-height: 36px;
      }
    }

    &-btn {
      right: 0;
      width: 56px;
      height: 40px;
      background-color: #398ce2;
      border: 1px solid #398ce2;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0px;
      color: #ffffff;
      cursor: pointer;
    }
  }
}

.exhibition-list {
  /deep/ .el-table__empty-text {
    width: 100%;
  }
}

.page-panel {
  margin-top: 5px;
  text-align: right;
}
</style>
